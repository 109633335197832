<template>
    <v-skeleton-loader
        v-if="isLoading"
        type="list-item-three-line"
    />
    <v-card v-else>
        <v-card-text>
            <div class="markdown-content" v-html="content" />
        </v-card-text>
    </v-card>
</template>

<script>
import request from '@/utils/request';
import marked from 'marked';

export default {
    data () {
        return {
            isLoading: false,
            content: null
        };
    },
    async mounted () {
        var currentCulture = this.$store.getters['session/currentCulture'];

        this.isLoading = true;
        var response;
        try {
            response = await request({
                baseURL: '/',
                url: (currentCulture && currentCulture !== 'en') ? `/content/tos.${currentCulture}.md` : '/content/tos.md',
                method: 'get'
            });
        } catch {
            response = await request({
                baseURL: '/',
                url: '/content/tos.md',
                method: 'get'
            });
        }

        this.content = marked(response.data);
        this.isLoading = false;
    }
};
</script>
