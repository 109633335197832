<template>
    <div>
        <v-card class="pa-1" :loading="isLoading">
            <v-card-title class="justify-center display-1 mb-2">
                {{ $t('views.passport.register.title') }}
            </v-card-title>
            <v-card-subtitle>
                {{ $t('views.passport.register.subtitle') }}
            </v-card-subtitle>

            <!-- sign up form -->
            <v-card-text v-if="passportViewModel">
                <v-window v-model="step" :touchless="true">
                    <v-window-item value="preCheck">
                        <PreCheck :passportViewModel="passportViewModel" @submitted="onPreChecked" />
                    </v-window-item>
                    <v-window-item value="register">
                        <Register :passportViewModel="passportViewModel" :preCheckResult="preCheckResult" :returnUrl="returnUrl" />
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>

        <div class="text-center mt-6">
            {{ $t('views.passport.register.hasAccount') }}
            <router-link :to="`${generateUrl('/passport/login', { returnUrl })}#email`" class="font-weight-bold">
                {{ $t('views.passport.register.login') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import FormMixins from '@/mixins/FormMixins';
import utils from '@/utils';
import { getPassportViewModel } from '@/api/passport';
import PreCheck from './PreCheck';
import Register from './Register';

export default {
    mixins: [FormMixins],
    components: {
        PreCheck,
        Register
    },
    data () {
        return {
            isLoading: false,

            // 通行证的配置
            passportViewModel: null,

            returnUrl: null,

            // 步骤，第一步预检查preCheck, 第二步注册register
            step: 'preCheck',

            preCheckResult: {
                country: 'none',
                email: '',
                mobilePhone: '',
                registerType: '',
                secret: ''
            }
        };
    },
    methods: {
        submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            try {

            } finally {
                this.isSubmitting = false;
            }
        },
        onPreChecked (preCheckResult) {
            for (var prop in preCheckResult) {
                this.$set(this.preCheckResult, prop, preCheckResult[prop]);
            }
            this.step = 'register';
        },
        signInProvider (provider) {}
    },
    async created () {
        this.isLoading = true;
        var returnUrl = this.returnUrl = utils.route.getQueryString(this.$route, 'returnUrl');
        this.passportViewModel = await getPassportViewModel({ returnUrl });
        this.isLoading = false;
    }
};
</script>
